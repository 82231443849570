<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center column>
        <FileImage :file="getLogo()" class="logo-campaign" :useBg="true" v-if="getLogo()!=null" />
        <div class="logo-login" v-else></div>
        <Container maxWidth="570">
          <v-layout row wrap>
            <v-flex v-if="$route.query.email" form-box text-xs-center pa-5>
              <div class="head-login">
                <h2>Redefinição de senha</h2>
                <p>Siga as instruções abaixo para finalizar o cadastro.</p>
              </div>

              <p class="py-5 f-size-16 text-gray-dark">
                Enviamos um e-mail de confirmação para
                <br />

                <strong class="text-default">{{ $route.query.email }}</strong>
                <!--email-->

                <br />
                <br />Verifique sua caixa de entrada.
              </p>

              <p>
                <v-btn
                  flat
                  round
                  color="white"
                  href="/login"
                  class="btn-primary px-5"
                  large
                >EFETUAR LOGIN</v-btn>
              </p>

              <div class="mt-4 caption">
                <div>
                  Não recebeu o e-mail?
                  <a
                    @click="resendRecoverPasswordEmail"
                    class="text-primary decor-none font-weight-bold"
                  >Enviar novamente.</a>
                </div>
                <div>Verifique se o e-mail não foi direcionado para a pasta spam.</div>
              </div>
            </v-flex>
          </v-layout>
        </Container>

        <div class="login-copyright">
          <v-layout w-100>
            <v-flex>
              Powered by
              <a href class="ma-0">abraceumacausa.com.br</a>
            </v-flex>
            <v-flex text-xs-right>
              <a href>Termos de uso</a>
              <a href>Política de Privacidade</a>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <FileImage :file="getBg()" :useBg="true" class="bg-campaign" v-if="getBg()!=null" />
    <div class="login-bg" v-else></div>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputText from "@/components/input/InputText.vue";
import UserService from "@/scripts/services/user.service";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";

export default {
  components: { Container, InputText, FileImage},
  data() {
    return {
      showContent: false,
      userService: new UserService(),
      campaignService: new CampaignService(),
      landingPageConfig: new CampaignLandingPageConfig()
    };
  },
  created() {
    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function(data) {
        this.landingPageConfig = data;
      }.bind(this)
    );
  },
  methods: {
    resendRecoverPasswordEmail() {
      this.userService.resendRecoverPasswordEmail(this.$route.query.username);
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    }
  }
};
</script>
